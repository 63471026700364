import React from 'react'

export const MicroCreche = () => {
    return (
        <>
            <section class="container mx-auto my-16 mt-36">


                <div class="flex flex-col lg:flex-row lg:items-center justify-center">
                    <div class="lg:w-4/5 sm:w-1/1">

                        <div id="enfant_play" class=" block md:md:hidden w-full lg:w-1/3 sm:w-1/1 p-3 lg:m-7">
                            <img class="h-full w rounded-xl"
                                src="/Assets/img/equipe.png"
                                alt="childplay" />
                        </div>



                        <div class="container lg:pr-20 sm:pr-1 my-2 p-7 lg:mt-2">

                            <h5 class="text-2xl text-indigo-900">Grain de Malice
                                <h1 id="text-grain" class="mt-1 text-6xl text-green-600">Nous connaître</h1>
                            </h5>

                            <h5 class="container lg:pr-20 my-10 font-semibold text-xl mt-5 mb-5 text-orange-500">
                                Notre équipe est composée de professionnelles titulaires de diplômes de la petite enfance :</h5>
                        </div>


                        <div class="container lg:pr-20 p-7 text-indigo-900 text-justify">
                            <p class="">
                                <h3 class="font-semibold">Cécile Berchoux : éducatrice de jeunes enfants</h3>
                                Cécile s’occupe au quotidien des enfants accueillis dans notre établissement (activités d’éveil, soins d’hygiène, repas…).
                                Elle est la référence technique de la micro crèche et assure ainsi le respect du projet pédagogique.
                                Cécile occupe la fonction de responsable de la structure. Elle est également cogérante de la société.

                                <h3 class="font-semibold mt-4">Aurélie Monard : titulaire du CAP petite enfance</h3>
                                Aurélie, cogérante de la société, s'occupe quotidiennement des enfants en leur proposant diverses activités et
                                en veillant à leurs soins essentiels (hygiène, repas, siestes, etc.).

                                <h3 class="font-semibold mt-4"> Marilyne Marchand : Titulaire du CAP petite enfance</h3>
                                Marilyne travaille au quotidien auprès des enfants en proposant des activités diverses et en assurant les soins
                                nécessaires aux enfants (hygiène, repas, siestes…)
                                Marilyne prépare les repas donnés aux enfants.
                            </p>


                            <div class="flex items-center mt-10">
                                <div class="lg:ml-16 lg:w-3/4 justify-center flex-row items-center">
                                    <img src="/Assets/img/happy-children.png" alt="Enfants_joyeux" /></div>
                            </div>

                        </div>
                        {/* <div class="flex items-center justify-between w-96 mt-5">
            <Link class="rounded-md py-2 px-4 bg-orange-400 text-white">Notre équipe</Link>
            <button class="mx-2 rounded-md py-2 px-4 flex items-center">
                <img class="mx-2 " src="/Assets/svg/picto-video.svg" alt="" /><p class="text-indigo-900">Voir la vidéo</p></button>
        </div> */}
                    </div>
                    <div id="enfant_play" class="hidden lg:block w-3/5 sm:1/2 m-10">
                        <img class="h-full w rounded-xl"
                            src="/Assets/img/creche_exterieur.png"
                            alt="parents_enfants" />

                        <img class="h-full w rounded-xl mt-10"
                            src="/Assets/img/equipe.png"
                            alt="parents_enfants" />
                    </div>
                </div>
                <div class="my-20"></div>
                {/* <button class="flex items-center">
<img class="mx-2" src="./ressources/svg/picto-scroll.svg" alt=""/>
En savoir plus
</button>  */}
            </section>
        </>
    )
}
