import React from 'react'

export const Footer = () => {
  return (
    <footer class="bg-gradient-to-r from-red-200 to-indigo-300 py-8">
      <div class="container mx-auto px-4 flex flex-col lg:flex-row justify-around space-y-8 lg:space-y-0">
        {/* Section Grain de Malice */}
        <div class="w-full lg:w-1/4 p-4 lg:p-8">
          <h3 class="text-xl font-semibold mb-4 text-black hover:text-gray-900 text-center">
            Grain de Malice
          </h3>
          <ul class="text-center">
            <li class="my-2">Du lundi au vendredi 7h30 à 18h30</li>
            <li class="my-2">Fermeture annuelle 5 semaines.</li>
          </ul>
        </div>

        {/* Section Adresse */}
        <div class="w-full lg:w-1/4 p-4 lg:p-8">
          <h3 class="text-xl font-semibold mb-4 text-black hover:text-gray-900 text-center">
            Adresse
          </h3>
          <ul class="text-center">
            <li class="my-2">41 Rue Marx Dormoy, 42300 Roanne</li>
          </ul>
        </div>

        {/* Section Contact */}
        <div class="w-full lg:w-1/4 p-4 lg:p-8">
          <h3 class="text-xl font-semibold mb-4 text-black hover:text-gray-900 text-center">
            Contact
          </h3>
          <ul class="text-center">
            <li class="my-2">Tél. : 09 81 65 51 57 ou 06 69 43 55 26</li>
            <li class="my-2">
              <a href="mailto:microcrecheroanne@gmail.com">microcrecheroanne@gmail.com</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>

  )
}
