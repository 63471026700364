import React from 'react'
import { Link } from 'react-router-dom'

export const Microcreche = () => {
    return (
        <section class="container mx-auto my-16 mt-36">
            <div class="flex flex-col lg:flex-row lg:items-center justify-center">
                <div class="lg:w-4/5 w-full">

                    <div id="enfant_play" class="block md:hidden w-5/6 lg:w-1/3 sm:w-full m-5 shadow-lg">
                        <img class="h-full w-full rounded-xl" src="/Assets/img/graindemalice-childs.png" alt="childplay" />
                    </div>

                    <div class="flex items-center mt-5">
                        <div class="ml-14 lg:w-1/4 w-2/3 flex justify-center lg:items-center">
                            <img src="/Assets/img/baby-crawling-with-green-diaper.png" alt="baby" />
                        </div>
                    </div>


                    <div class="container lg:pr-20 sm:pr-1 my-2 p-7 lg:mt-2">
                        <h5 class="text-4xl text-indigo-900">Micro-crèche
                            <h1 id="text-grain" class="mt-1 text-6xl text-green-600">Grain de Malice</h1>
                        </h5>
                        <h5 class="font-semibold text-xl text-orange-500">
                            Une capacité d'accueil de 12 enfants, encadrés par 4 professionnels
                        </h5>
                    </div>
                    <div class="container lg:pr-20 p-7 text-indigo-900 text-justify">
                        <p><b>Notre expérience et notre formation professionnelle : un gage de qualité.</b> Les enfants sont encadrés par des professionnels titulaires de diplômes reconnus (diplôme d’Etat d’Educateur de Jeunes Enfants, Diplôme de la petite enfance…) ayant une large expérience et réflexion dans le domaine de l’accueil du jeune enfant. Donc un gage de qualité supplémentaire. Nous sommes une équipe de 4 professionnelles à suivre le développement des enfants.</p>
                        <p class="mt-5"><b>Une micro-crèche privée avec une vision différente.</b> Nous ne sommes pas une société de crèches avec une gestion d’entreprise. <b>Nous avons créé un projet à petite échelle qui met en avant les valeurs pédagogiques qui nous tiennent à cœur.</b> Ce projet est à l’initiative de deux professionnelles de la petite enfance expérimentées.</p>
                    </div>

                    <div class="flex mt-6 ml-4">
                        <button id="bottom_little_new_recette" class="rounded-md py-2 px-3 bg-orange-400 text-white">
                            <Link to="/about" class="text-white no-underline font-semibold hover:text-lime-100">Notre équipe</Link>
                        </button>
                        <button id="bottom_little_salade" class="ml-5 rounded-md py-2 px-3 bg-lime-400">
                            <Link to="/projects" class="text-cyan-800 no-underline font-semibold hover:text-lime-700">Projet pédagogique</Link>
                        </button>
                    </div>
                </div>
                <div id="enfant_play" class="hidden lg:block lg:w-2/5 sm:w-1/2 m-10">
                    <img class="h-full rounded-xl" src="/Assets/img/sieste.png" alt="child_play" />
                    <img class="mt-16 h-full rounded-xl" src="/Assets/img/graindemalice-childs.png" alt="child_play" />
                </div>
            </div>
            <div class="my-20"></div>
        </section>

    )
}
