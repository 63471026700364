import React from 'react'

export const Maps = () => {
    return (
        <>
            <div class="container mx-auto flex justify-center mb-10 rounded-md p-4">
                <iframe
                    title="Location of Grain de Malice"
                    class="rounded-lg border-cyan-300"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2769.5289717639025!2d4.064352877121141!3d46.040546294798645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47f40f60b3faba2d%3A0x70a77300f3a12174!2sGrain%20de%20Malice!5e0!3m2!1sfr!2sfr!4v1722426815788!5m2!1sfr!2sfr"
                    width="700"
                    height="450"
                    style={{ border: 2 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>
        </>
    )
}
