import React from 'react'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import { Microcreche } from '../components/Accueil/Microcreche'
import { Repas } from '../components/Accueil/Repas'
import { PreInscription } from '../components/Accueil/PreInscription'
import { Activite } from '../components/Accueil/Activite'
import CookieConsent from "react-cookie-consent";

export const Accueil = () => {
  return (
    <>
      <Header />
      <Microcreche />
      <Repas />
      <section class="container mx-auto mt-4">
        <PreInscription />
        <Activite />
      </section>
      <Footer />
    </>
  )
}

<CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>