import React from "react";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { Microcreches } from "../components/Projet/Microcreches";

export const Projects = () => {
  return (
    <>
      <Header />
      <Microcreches />
      <section class="container mx-auto my-16 mt-16 bg-cyan-50 rounded-md lg:p-9 p-6">
        <div>
          <p class="leading-relaxed">
            <div class="text-justify">
              <p id="text-grain" class="mt-1 text-3xl md:text-5xl text-green-600 mb-3">Le projet pédagogique en détails</p>
              <h3 class="font-semibold text-2xl mt-5 text-orange-500">L’accueil des enfants </h3>
              <h4 class="font-bold text-lg mt-2 mb-2 text-indigo-900" >l’accueil journalier du matin et du soir.</h4>

              C’est un moment privilégié qui permet de faire le lien entre la
              maison et la micro crèche. C’est un moment d’échange où l’on parle
              de l’enfant et de ses habitudes, de son quotidien. Cet accueil est
              personnalisé et adapté à chaque enfant et à chaque famille. Les
              professionnels sont disponibles pour échanger avec les familles.
              Nous nous servons d’un petit cahier comme support de communication
              et d’échange avec les familles. Les professionnelles notent les
              anecdotes concernant l’enfant ainsi que les points important de sa
              journée. Nous invitons les familles à se l’approprier. Il
              deviendra ainsi une mémoire de la vie de l’enfant dans la
              micro-crèche. La période d’adaptation. Elle permet l’accueil de
              l’enfant à la micro crèche en douceur. Cette période permet
              d’instaurer la confiance et le dialogue entre les parents, enfant
              et professionnels. L’enfant peut alors faire connaissance avec
              l’environnement dans lequel il évoluera et se familiariser avec
              les enfants accueillis.
              <h3 class="font-semibold text-2xl mt-5 text-orange-500">L’apprentissage de l’autonomie</h3>
              <h4 class="font-bold text-lg mt-2 mb-2 text-indigo-900" >Il est important que l’enfant apprenne à faire par lui-même</h4>

              Il est important que l’enfant apprenne à faire par lui-même et développe
              sa relation à l’autre : Par les activités proposées et le jeu
              libre. Par la motricité. Nous laissons l’enfant évoluer en
              fonction de ses capacités. Nous suivons son rythme et son
              développement et l’encourageons dans son apprentissage. Nous
              respectons l’évolution différente de chaque enfant. Par
              l’acquisition de la propreté et le change. Nous suivons la demande
              de l’enfant et prenons en compte sa maturité avec l’accord de ses
              parents. Nous respectons l’enfant et sa pudeur lors des changes et
              des passages aux toilettes. Par le sommeil. Nous respectons le
              rythme et les besoins des enfants. Chaque enfant possède son
              propre lit. Par le langage. Nous accordons un intérêt tout
              particulier au langage et échangeons au maximum avec les enfants
              car le langage est le prémisse de la vie sociale. Par
              l’alimentation. Nous faisons des repas des moments privilégiés,
              conviviaux. Les repas sont proposés aux enfants en fonction de
              leurs rythmes et sont adaptés à leur âge et à leur développement.
              Les menus sont variés et nous cuisinons sur place avec un maximum
              de produits frais.

              <h3 class="font-semibold text-2xl mt-5 text-orange-500"> Le respect de l’enfant et de sa famille </h3>
              <h4 class="font-bold text-lg mt-2 mb-2 text-indigo-900" >Nous respectons l’enfant en tant que personne et individu à part entière</h4>


              Nous
              respectons l’enfant en tant que personne et individu à part
              entière en étant vigilantes à : Son rythme Sa sécurité affective
              et physique Ses origines Au respect de son bien être.

              <h3 class="font-semibold text-2xl mt-5 text-orange-500"> L’équipe </h3>
              <h4 class="font-bold text-lg mt-2 mb-2 text-indigo-900" >Elle se compose de professionnels diplômés</h4>
              Elle se compose de professionnels diplômés dont une éducatrice
              de jeunes enfants qui exerce également la fonction de responsable
              et co-gérante, une auxiliaire de puériculture et de
              professionnelles titulaire du CAP petite enfance. Elles placent
              l’enfant au centre de leurs préoccupations et travaillent en
              respectant le projet pédagogique.

              <h3 class="font-semibold text-2xl mt-5 text-orange-500">Les parents </h3>
              <h4 class="font-bold text-lg mt-2 mb-2 text-indigo-900" > Les parents ont une place importante dans notre micro-crèche</h4>
              Les parents ont une place importante dans notre micro-crèche. Nous les incitons
              vivement à prendre part à la vie de la structure. Nous proposons
              aux parents, s’ils le désirent, de participer occasionnellement à
              des activités d’éveil avec le groupe d’enfants en proposant un
              thème qui les intéresse. Nous sommes à la disposition des parents
              pour échanger sur leur enfant lors de l’accueil mais aussi lors de
              réunions ou soirées organisées pour les parents. Les stagiaires
              Nous sommes favorables à l’accueil de stagiaires exerçant une
              formation diplômante dans le secteur de la petite enfance.

              <h3 class="font-semibold text-2xl mt-5 text-orange-500">Les stagiaires</h3>
              <h4 class="font-bold text-lg mt-2 mb-2 text-indigo-900" >Les stagiaires ne sont pas là pour remplacer le personnel</h4>
              Les stagiaires ne sont pas là pour remplacer le personnel mais pour se
              former. Ils participent aux activités sous le contrôle d’une
              personne de référence. Chaque stagiaire est accueilli par sa
              référante et la directrice. Nous n’acceptons pas de stage dont la
              durée est inférieur à un mois afin de ne pas déstabiliser les
              enfants. (Une période d’observation d’une semaine est appliquée
              pour chaque stage)

              <h3 class="font-semibold text-2xl mt-5 text-orange-500">L’ouverture vers l’extérieur </h3>
              <h4 class="font-bold text-lg mt-2 mb-2 text-indigo-900" >Nous proposons aux enfants des sorties</h4>
              Nous proposons aux enfants dans la mesure du possible des sorties à l’extérieur
              telles que : Sorties au square, au marché, aux halles, à la
              médiathèque… Nous proposons également une séance musique encadrée
              par une intervenante du SIEMAR (école de musique de la Ville de
              Roanne) un lundi sur deux ainsi que des séances d’éveil corporel
              (durant 6 séances par an). Des pensionnaires de la maison de
              retraite voisine viennent nous rendre visite un mardi par mois en
              matinée pour échanger et partager des activités avec les enfants.

              <h3 class="font-semibold text-2xl mt-5 text-orange-500">L’aménagement de l’espace</h3>
              <h4 class="font-bold text-lg mt-2 mb-2 text-indigo-900" >L’aménagement de nos locaux</h4>
              Nous avons travaillé sur l’aménagement
              de nos locaux afin de les adapter aux exigences d’une micro-crèche
              et les rendre fonctionnels pour les familles et les
              professionnels. Nous nous sommes efforcés de les rendre
              accueillants et agréables pour tous. Puis nous avons travaillé sur
              la disposition et le choix du mobilier. En effet, le mobilier doit
              être particulièrement adapté à l’ergonomie des enfants.
              L’aménagement de l’espace influence le comportement des enfants,
              dès lors une organisation adéquate permet de canaliser le groupe,
              de faciliter le jeu et de laisser chacun évoluer sereinement.

              <h3 class="font-semibold text-2xl mt-5 text-orange-500">Notre démarche environnementale</h3>
              <h4 class="font-bold text-lg mt-2 mb-2 text-indigo-900" >Quelques règles pour un environnement sain</h4>
              Nous nous soucions de respecter
              quelques règles de principe afin que les enfants évoluent dans un
              environnement plus sain en utilisant des produits ménagers et de
              toilette certifiés écologiques voir bios, en évitant au maximum
              les produits jetables, en faisant attention au gaspillage de
              matières premières et d’énergie, en proposant des produits de
              saison et des viandes françaises au menu…
            </div>
          </p>
        </div>
      </section>
      <Footer />
    </>
  );
};
