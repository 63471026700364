import React from 'react'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import { MicroCreche } from '../components/AboutUs/MicroCreche'
import { Maps } from '../components/AboutUs/Maps'


// import { PreInscription } from '../components/Accueil/PreInscription'
// import { Activite } from '../components/Accueil/Activite'
// import GoogleReviews from '../components/AboutUs/GoogleReviews';

export const AboutUs = () => {
  return (
    <>
      <Header />
      <MicroCreche />
      {/* <GoogleReviews /> */}
      <section class="container mx-auto mt-4">
        {/* <Activite /> */}
        <Maps />
      </section>
      <Footer />
    </>
  )
}
