import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ScrollRestoration } from "react-router-dom";
import "./App.css";
import { Accueil } from "./Pages/Accueil";
import { AboutUs } from "./Pages/AboutUs";
import { Projects } from "./Pages/Projects";
import { Inscription } from "./Pages/Inscription";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <ScrollRestoration />
        <Accueil />
      </>
    ),
  },
  {
    path: "/about",
    element: (
      <>
        <ScrollRestoration />
        <AboutUs />,
      </>
    ),
  },
  {
    path: "/projects",
    element: (
      <>
        <ScrollRestoration />
        <Projects />,
      </>
    ),
  },
  {
    path: "/pre-inscription",
    element: (
      <>
        <ScrollRestoration />
        <Inscription />,
      </>
    ),
  },
]);

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
