import React from 'react'
// import { Link } from 'react-router-dom'



export const PreInscription = () => {

    return (
        <>

            <section>

                <div class="flex flex-col lg:flex-row items-center justify-center mt-5">
                    <div class="w-80 lg:w-72 mx-auto mb-4 lg:mb-0 lg:mr-4">
                        <img src="/Assets/img/evolution_girl.png" alt="activités" />
                    </div>
                    <div class="w-80 lg:w-96 mx-auto ">
                        <img src="/Assets/img/evolution_boy.png" alt="activités" />
                    </div>
                </div>


                <div class="flex flex-col lg:flex-row items-center justify-center mt-8 bg-gradient-to-r from-cyan-100 to-blue-200 rounded-md m-4 p-4 lg:p-6">

                    <div class="text-center text-lg lg:text-lg font-medium">
                        Nous mettons en place quotidiennement des activités diverses et variées pour les enfants.
                        <br /> Nous prenons en compte l’âge, la capacité et l’envie de l’enfant. <br />Elles ne sont en aucun cas imposées aux enfants.
                        <br /> Voici quelques exemples d’activités que nous proposons régulièrement à la micro crèche :
                    </div>
                </div>
            </section>
        </>

    )
}


