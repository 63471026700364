import React, { useState, useEffect } from 'react';

export const Formulaire = () => {
    // États pour la première partie du formulaire
    const [prenom, setPrenom] = useState('');
    const [nom, setNom] = useState('');
    const [tel, setTel] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [comment, setComment] = useState('');

    // États pour le calcul de la PAJE
    const [nombreEnfants, setNombreEnfants] = useState('');
    const [revenu, setRevenu] = useState('');
    const [isParentIsolé, setIsParentIsolé] = useState(false);
    const [montantAide, setMontantAide] = useState('');
    const [selectedTable, setSelectedTable] = useState('');

    const handleNombreEnfantsChange = (e) => {
        setNombreEnfants(e.target.value);
    };

    const handleRevenuChange = (e) => {
        setRevenu(e.target.value);
    };

    const handleTableChange = (e) => {
        setSelectedTable(e.target.value);
    };

    const handleReset = (e) => {
        e.preventDefault();
        setPrenom('');
        setNom('');
        setTel('');
        setEmail('');
        setAddress('');
        setCity('');
        setZipcode('');
        setComment('');
        setNombreEnfants('');
        setRevenu('');
        setIsParentIsolé(false);
        setMontantAide('');
        setSelectedTable('');
    };

    useEffect(() => {
        let selectedRevenu = 0;

        if (revenu) {
            selectedRevenu = parseFloat(revenu);
        }

        if (isParentIsolé) {
            if (selectedRevenu === 911.23) {
                selectedRevenu = 1184.60;
            } else if (selectedRevenu === 785.51) {
                selectedRevenu = 1021.16;
            } else if (selectedRevenu === 659.84) {
                selectedRevenu = 857.79;
            }
        }

        setMontantAide(selectedRevenu.toFixed(2));
    }, [revenu, isParentIsolé]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = {
            prenom,
            nom,
            tel,
            email,
            address,
            city,
            zipcode,
            comment,
            nombreEnfants,
            revenu,
            isParentIsolé,
            montantAide,
            selectedTable
        };

        console.log(formData);
    };

    return (
        <>
            <section class="container mx-auto my-16 mt-36 ">

                <div>
                    <div class="min-h-screen p-6 flex items-center justify-center">
                        <div class="container max-w-screen-lg mx-auto">
                            <div>
                                <h2 class="font-semibold text-4xl text-green-800">Formulaire de pré-inscription</h2>
                                <p class="text-gray-500 mb-6">En cas de questions, merci de les indiquer dans la case
                                    commentaire.</p>

                                <h2 class="font-light text-sm text-orange-600 mb-4">Notre structure se présente sous forme d’une
                                    petite SARL, elle est donc privée.
                                </h2>

                                <form onSubmit={handleSubmit} name="formulairepreinscription">

                                    <div class="bg-gradient-to-r from-red-200 to-indigo-200 rounded shadow-lg p-4 px-4 md:p-8 mb-6 ">
                                        <div class="text-gray-600">
                                            <p class="font-medium text-xl mb-7">Vos informations :</p>
                                            {/* <p class="text-sm">Aucune donnée personnelle n'est conservée par notre site via ce
                                                formulaire.</p> */}
                                        </div>
                                        <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-1">
                                            <div class="lg:col-span-2">

                                                <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5 ">
                                                    <div class="md:col-span-5">
                                                        <label class="lg:text-lg" for="Prenom">Prénom</label>
                                                        <input type="text" name="Prenom" id="Prenom" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={prenom} onChange={(e) => setPrenom(e.target.value)} />
                                                    </div>

                                                    <div class="md:col-span-5 mt-3">
                                                        <label class="lg:text-lg" for="Nom">Nom</label>
                                                        <input type="text" name="Nom" id="Nom" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={nom} onChange={(e) => setNom(e.target.value)} />
                                                    </div>

                                                    <div class="md:col-span-5 mt-3">
                                                        <label class="lg:text-lg" for="tel">Téléphone</label>
                                                        <input type="tel" name="tel" id="tel" required minLength="10" maxLength="10" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={tel} onChange={(e) => setTel(e.target.value)} />
                                                    </div>

                                                    <div class="md:col-span-5 mt-3">
                                                        <label class="lg:text-lg" for="email">Adresse Mail</label>
                                                        <input type="text" name="email" id="email" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                    </div>

                                                    {/* <div class="md:col-span-2 mt-3">
                                                        <label class="lg:text-lg" for="city">Numéro (adresse)</label>
                                                        <input type="number" name="numero" id="numero" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={numero} onChange={(e) => setNumero(e.target.value)} placeholder="" />
                                                    </div> */}

                                                    <div class="md:col-span-3 mt-3">
                                                        <label class="lg:text-lg" for="address">Addresse</label>
                                                        <input type="text" name="address" id="address" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={address} onChange={(e) => setAddress(e.target.value)} placeholder="" />
                                                    </div>

                                                    <div class="md:col-span-2 mt-3">
                                                        <label class="lg:text-lg" for="zipcode">Code
                                                            Postal</label>
                                                        <input type="text" name="zipcode" id="zipcode" class="transition-all flex items-center h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={zipcode} onChange={(e) => setZipcode(e.target.value)} />
                                                    </div>

                                                    <div class="md:col-span-5 mt-3">
                                                        <label class="lg:text-lg" for="city">Ville</label>
                                                        <input type="text" name="city" id="city" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={city} onChange={(e) => setCity(e.target.value)} />
                                                    </div>

                                                </div>

                                                <div class="md:col-span-3 mt-10 border-gray-500 border rounded-md p-10">

                                                    <div>
                                                        <div className="md:col-span-5">
                                                            <label className="lg:text-lg font-semibold text-indigo-900" htmlFor="Prenom">Calcul de l'aide de la PAJE <p class="text-sm">(Prestation Accueil Jeune Enfant)</p></label>
                                                            <p class="text-xs mt-3 text-gray-600">ce formulaire est une simulation et n'engage en rien la micro-crèche sur ses tarifs.</p>
                                                        </div>

                                                        <div class="border border-y-1 mt-5 border-gray-400"></div>



                                                        <div className="md:col-span-2 mt-8 text-md text-gray-600">
                                                            <label htmlFor="nombre-enfants">Nombre d'enfants (au foyer) :</label>
                                                        </div>
                                                        <div className="md:col-span-2 mt-4">
                                                            <select id="nombre-enfants" class="p-2 text-center font-semibold rounded-lg text-indigo-900 lg:text-base text-xs" value={nombreEnfants} onChange={handleNombreEnfantsChange}>
                                                                <option value="">-- Choisissez le nombre d'enfants --</option>
                                                                <option value="1">1 enfant</option>
                                                                <option value="2">2 enfants</option>
                                                                <option value="3">3 enfants</option>
                                                            </select>
                                                        </div>

                                                        <div className="md:col-span-2 text-md mt-8 text-gray-600">
                                                            <label class="" htmlFor="revenu">Plafond de revenu pour {nombreEnfants} enfant{nombreEnfants > 1 ? 's' : ''} au foyer :</label>
                                                        </div>
                                                        <div className="md:col-span-2 mt-4">
                                                            <select
                                                                name="revenu"
                                                                id="revenu"
                                                                className="revenu-select p-3 text-center font-semibold rounded-lg text-indigo-900 lg:text-base text-xs"
                                                                value={revenu}
                                                                onChange={handleRevenuChange}
                                                            >
                                                                <option className="lg:text-lg p-3 " value=""> Choisissez votre plafond de revenu </option>
                                                                <option value="911.23">plafond de revenu 1 : {nombreEnfants === "1" ? "21 320 €" : nombreEnfants === "2" ? "24 346 €" : "27 372 €"}</option>
                                                                <option value="785.51">plafond de revenu 2 : {nombreEnfants === "1" ? "47 377 €" : nombreEnfants === "2" ? "54 102 €" : "60 827 €"}</option>
                                                                <option value="659.84">plafond de revenu 3 : {nombreEnfants === "1" ? "47 377 €" : nombreEnfants === "2" ? "54 102 €" : "60 827 €"}</option>
                                                            </select>
                                                        </div>

                                                        <div className="md:col-span-2 text-md mt-8 font-semibold text-indigo-900">
                                                            <label htmlFor="montant-aide">Résultat montant forfait CAF pour les - de 3 ans :</label>
                                                            <input
                                                                type="number"
                                                                id="montant-aide"
                                                                className="h-10 border mt-3 rounded px-4 w-full bg-gray-50"
                                                                value={montantAide}
                                                                readOnly
                                                            />
                                                        </div>

                                                        <div className="md:col-span-5 mt-3">
                                                            <div className="inline-flex items-center">
                                                                <input
                                                                    type="checkbox"
                                                                    name="billing_same"
                                                                    id="billing_same"
                                                                    className="form-checkbox"
                                                                    checked={isParentIsolé}
                                                                    onChange={(e) => setIsParentIsolé(e.target.checked)}
                                                                />
                                                                <label htmlFor="billing_same" className="ml-2">cocher si parents isolé</label>
                                                            </div>

                                                            <div className="md:col-span-2 mt-3">
                                                                <button
                                                                    id="reset-button"
                                                                    className="bg-red-400 hover:bg-red-500 text-white items-center px-9 py-2 rounded-lg"
                                                                    onClick={handleReset}
                                                                >
                                                                    Réinitialiser le calcul
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="md:col-span-8 lg:col-span-12 mt-5 border-gray-500 border rounded-md p-10">
                                                        <div class="md:col-span-5 mt-2">
                                                        </div>

                                                        <div class="md:col-span-3 mt-3">
                                                            <label class="text-xl" for="QF">Séléctionner votre quotient familial : </label>
                                                            <select class="block w-full px-4 py-2 mt-1 text-gray-700 bg-white 
                                                            border border-gray-300 rounded-md shadow-sm focus:outline-none
                                                            focus:ring-blue-500 focus:border-blue-500" id="QF" value={selectedTable} onChange={handleTableChange}>

                                                                <option value="1">0 - 750 €</option>
                                                                <option value="2">750 - 1000 €</option>
                                                                <option value="3">1000 - 1250 €</option>
                                                                <option value="4">1250 - 1500 €</option>
                                                                <option value="5">1500 - 1750 €</option>
                                                                <option value="6"> simbole supperieur 1750 €</option>
                                                            </select>
                                                        </div>


                                                        {selectedTable === '1' && (
                                                            <div id="TQF1" class="overflow-x-auto mt-8">
                                                                <table class="min-w-full bg-white border border-gray-300 rounded-md">
                                                                    <caption class="p-4 md:text-sm font-semibold text-left text-gray-800 bg-gray-100 border-b border-gray-300 rounded-t-md">
                                                                        COÛT MENSUEL POUR LES FAMILLES POUR UN TARIF A 7.10€/h
                                                                    </caption>
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="p-4 border border-gray-300">Nombre d'heures d'accueil par semaine</th>
                                                                            <th class="p-4 border border-gray-300">1/2 jour:<br />4h30</th>
                                                                            <th class="p-4 border border-gray-300">1 jour:<br />9h</th>
                                                                            <th class="p-4 border border-gray-300">2 jours:<br />18h</th>
                                                                            <th class="p-4 border border-gray-300">3 jours:<br />27h</th>
                                                                            <th class="p-4 border border-gray-300">4 jours:<br />36h</th>
                                                                            <th class="p-4 border border-gray-300">5 jours:<br />45h</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td class="p-4 border border-gray-300">1° niv. revenu (PAJE déduite)</td>
                                                                            <td class="p-4 border border-gray-300">18€</td>
                                                                            <td class="p-4 border border-gray-300">37€</td>
                                                                            <td class="p-4 border border-gray-300">73€</td>
                                                                            <td class="p-4 border border-gray-300">110€</td>
                                                                            <td class="p-4 border border-gray-300">147€</td>
                                                                            <td class="p-4 border border-gray-300">314€</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="p-4 border border-gray-300">2° niv. revenu (PAJE déduite)</td>
                                                                            <td class="p-4 border border-gray-300">18€</td>
                                                                            <td class="p-4 border border-gray-300">37€</td>
                                                                            <td class="p-4 border border-gray-300">73€</td>
                                                                            <td class="p-4 border border-gray-300">110€</td>
                                                                            <td class="p-4 border border-gray-300">194€</td>
                                                                            <td class="p-4 border border-gray-300">439€</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="p-4 border border-gray-300">3° niv. revenu (PAJE déduite)</td>
                                                                            <td class="p-4 border border-gray-300">18€</td>
                                                                            <td class="p-4 border border-gray-300">37€</td>
                                                                            <td class="p-4 border border-gray-300">73€</td>
                                                                            <td class="p-4 border border-gray-300">110€</td>
                                                                            <td class="p-4 border border-gray-300">320€</td>
                                                                            <td class="p-4 border border-gray-300">565€</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="p-4 border border-gray-300">coût total de l'accueil <br /> (PAJE non déduite)
                                                                            </td>
                                                                            <td class="p-4 border border-gray-300">121€</td>
                                                                            <td class="p-4 border border-gray-300">245€</td>
                                                                            <td class="p-4 border border-gray-300">490€</td>
                                                                            <td class="p-4 border border-gray-300">735€</td>
                                                                            <td class="p-4 border border-gray-300">980€</td>
                                                                            <td class="p-4 border border-gray-300">1225€</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="p-4 border border-gray-300">MENSUALISATION SUR 12 MOIS</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        )}

                                                        {selectedTable === '2' && (
                                                            <div id="TQF2" class="overflow-x-auto mt-8">
                                                                <table class="min-w-full bg-white border border-gray-300 rounded-md">
                                                                    <caption class="p-4 md:text-md font-semibold text-left text-gray-800 bg-gray-100 border-b border-gray-300 rounded-t-md">
                                                                        COÛT MENSUEL POUR LES FAMILLES POUR UN TARIF A 7.20€/h
                                                                    </caption>
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="p-4 border border-gray-300">Nombre d'heures d'accueil par semaine</th>
                                                                            <th class="p-4 border border-gray-300">1/2 jour:<br />4h30</th>
                                                                            <th class="p-4 border border-gray-300">1 jour:<br />9h</th>
                                                                            <th class="p-4 border border-gray-300">2 jours:<br />18h</th>
                                                                            <th class="p-4 border border-gray-300">3 jours:<br />27h</th>
                                                                            <th class="p-4 border border-gray-300">4 jours:<br />36h</th>
                                                                            <th class="p-4 border border-gray-300">5 jours:<br />45h</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td class="p-4 border border-gray-300">1° niv. revenu (PAJE déduite)</td>
                                                                            <td class="p-4 border border-gray-300">18€</td>
                                                                            <td class="p-4 border border-gray-300">37€</td>
                                                                            <td class="p-4 border border-gray-300">73€</td>
                                                                            <td class="p-4 border border-gray-300">110€</td>
                                                                            <td class="p-4 border border-gray-300">147€</td>
                                                                            <td class="p-4 border border-gray-300">314€</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="p-4 border border-gray-300">2° niv. revenu (PAJE déduite)</td>
                                                                            <td class="p-4 border border-gray-300">18€</td>
                                                                            <td class="p-4 border border-gray-300">37€</td>
                                                                            <td class="p-4 border border-gray-300">73€</td>
                                                                            <td class="p-4 border border-gray-300">110€</td>
                                                                            <td class="p-4 border border-gray-300">194€</td>
                                                                            <td class="p-4 border border-gray-300">439€</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="p-4 border border-gray-300">3° niv. revenu (PAJE déduite)</td>
                                                                            <td class="p-4 border border-gray-300">18€</td>
                                                                            <td class="p-4 border border-gray-300">37€</td>
                                                                            <td class="p-4 border border-gray-300">73€</td>
                                                                            <td class="p-4 border border-gray-300">110€</td>
                                                                            <td class="p-4 border border-gray-300">320€</td>
                                                                            <td class="p-4 border border-gray-300">565€</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="p-4 border border-gray-300">coût total de l'accueil <br /> (PAJE non déduite)
                                                                            </td>
                                                                            <td class="p-4 border border-gray-300">121€</td>
                                                                            <td class="p-4 border border-gray-300">245€</td>
                                                                            <td class="p-4 border border-gray-300">490€</td>
                                                                            <td class="p-4 border border-gray-300">735€</td>
                                                                            <td class="p-4 border border-gray-300">980€</td>
                                                                            <td class="p-4 border border-gray-300">1225€</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="p-4 border border-gray-300">MENSUALISATION SUR 12 MOIS</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        )}

                                                        {selectedTable === '3' && (
                                                            <div id="TQF3" class="overflow-x-auto mt-8">
                                                                <table class="min-w-full bg-white border border-gray-300 rounded-md">
                                                                    <caption class="p-4 text-lg font-semibold text-left text-gray-800 bg-gray-100 border-b border-gray-300 rounded-t-md">
                                                                        COÛT MENSUEL POUR LES FAMILLES POUR UN TARIF A 7.30€/h
                                                                    </caption>
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="p-4 border border-gray-300">Nombre d'heures d'accueil par semaine</th>
                                                                            <th class="p-4 border border-gray-300">1/2 jour:<br />4h30</th>
                                                                            <th class="p-4 border border-gray-300">1 jour:<br />9h</th>
                                                                            <th class="p-4 border border-gray-300">2 jours:<br />18h</th>
                                                                            <th class="p-4 border border-gray-300">3 jours:<br />27h</th>
                                                                            <th class="p-4 border border-gray-300">4 jours:<br />36h</th>
                                                                            <th class="p-4 border border-gray-300">5 jours:<br />45h</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td class="p-4 border border-gray-300">1° niv. revenu (PAJE déduite)</td>
                                                                            <td class="p-4 border border-gray-300">18€</td>
                                                                            <td class="p-4 border border-gray-300">37€</td>
                                                                            <td class="p-4 border border-gray-300">73€</td>
                                                                            <td class="p-4 border border-gray-300">110€</td>
                                                                            <td class="p-4 border border-gray-300">147€</td>
                                                                            <td class="p-4 border border-gray-300">314€</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="p-4 border border-gray-300">2° niv. revenu (PAJE déduite)</td>
                                                                            <td class="p-4 border border-gray-300">18€</td>
                                                                            <td class="p-4 border border-gray-300">37€</td>
                                                                            <td class="p-4 border border-gray-300">73€</td>
                                                                            <td class="p-4 border border-gray-300">110€</td>
                                                                            <td class="p-4 border border-gray-300">194€</td>
                                                                            <td class="p-4 border border-gray-300">439€</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="p-4 border border-gray-300">3° niv. revenu (PAJE déduite)</td>
                                                                            <td class="p-4 border border-gray-300">18€</td>
                                                                            <td class="p-4 border border-gray-300">37€</td>
                                                                            <td class="p-4 border border-gray-300">73€</td>
                                                                            <td class="p-4 border border-gray-300">110€</td>
                                                                            <td class="p-4 border border-gray-300">320€</td>
                                                                            <td class="p-4 border border-gray-300">565€</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="p-4 border border-gray-300">coût total de l'accueil <br /> (PAJE non déduite)
                                                                            </td>
                                                                            <td class="p-4 border border-gray-300">121€</td>
                                                                            <td class="p-4 border border-gray-300">245€</td>
                                                                            <td class="p-4 border border-gray-300">490€</td>
                                                                            <td class="p-4 border border-gray-300">735€</td>
                                                                            <td class="p-4 border border-gray-300">980€</td>
                                                                            <td class="p-4 border border-gray-300">1225€</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="p-4 border border-gray-300">MENSUALISATION SUR 12 MOIS</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        )}

                                                        {selectedTable === '4' && (
                                                            <div id="TQF4" class="overflow-x-auto mt-8">
                                                                <table class="min-w-full bg-white border border-gray-300 rounded-md">
                                                                    <caption class="p-4 text-lg font-semibold text-left text-gray-800 bg-gray-100 border-b border-gray-300 rounded-t-md">
                                                                        COÛT MENSUEL POUR LES FAMILLES POUR UN TARIF A 7.40€/h
                                                                    </caption>
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="p-4 border border-gray-300">Nombre d'heures d'accueil par semaine</th>
                                                                            <th class="p-4 border border-gray-300">1/2 jour:<br />4h30</th>
                                                                            <th class="p-4 border border-gray-300">1 jour:<br />9h</th>
                                                                            <th class="p-4 border border-gray-300">2 jours:<br />18h</th>
                                                                            <th class="p-4 border border-gray-300">3 jours:<br />27h</th>
                                                                            <th class="p-4 border border-gray-300">4 jours:<br />36h</th>
                                                                            <th class="p-4 border border-gray-300">5 jours:<br />45h</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td class="p-4 border border-gray-300">1° niv. revenu (PAJE déduite)</td>
                                                                            <td class="p-4 border border-gray-300">18€</td>
                                                                            <td class="p-4 border border-gray-300">37€</td>
                                                                            <td class="p-4 border border-gray-300">73€</td>
                                                                            <td class="p-4 border border-gray-300">110€</td>
                                                                            <td class="p-4 border border-gray-300">147€</td>
                                                                            <td class="p-4 border border-gray-300">314€</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="p-4 border border-gray-300">2° niv. revenu (PAJE déduite)</td>
                                                                            <td class="p-4 border border-gray-300">18€</td>
                                                                            <td class="p-4 border border-gray-300">37€</td>
                                                                            <td class="p-4 border border-gray-300">73€</td>
                                                                            <td class="p-4 border border-gray-300">110€</td>
                                                                            <td class="p-4 border border-gray-300">194€</td>
                                                                            <td class="p-4 border border-gray-300">439€</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="p-4 border border-gray-300">3° niv. revenu (PAJE déduite)</td>
                                                                            <td class="p-4 border border-gray-300">18€</td>
                                                                            <td class="p-4 border border-gray-300">37€</td>
                                                                            <td class="p-4 border border-gray-300">73€</td>
                                                                            <td class="p-4 border border-gray-300">110€</td>
                                                                            <td class="p-4 border border-gray-300">320€</td>
                                                                            <td class="p-4 border border-gray-300">565€</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="p-4 border border-gray-300">coût total de l'accueil <br /> (PAJE non déduite)
                                                                            </td>
                                                                            <td class="p-4 border border-gray-300">121€</td>
                                                                            <td class="p-4 border border-gray-300">245€</td>
                                                                            <td class="p-4 border border-gray-300">490€</td>
                                                                            <td class="p-4 border border-gray-300">735€</td>
                                                                            <td class="p-4 border border-gray-300">980€</td>
                                                                            <td class="p-4 border border-gray-300">1225€</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="p-4 border border-gray-300">MENSUALISATION SUR 12 MOIS</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        )}

                                                        {selectedTable === '5' && (
                                                            <div id="TQF5" class="overflow-x-auto mt-8">
                                                                <table class="min-w-full bg-white border border-gray-300 rounded-md">
                                                                    <caption class="p-4 text-lg font-semibold text-left text-gray-800 bg-gray-100 border-b border-gray-300 rounded-t-md">
                                                                        COÛT MENSUEL POUR LES FAMILLES POUR UN TARIF A 7.50€/h
                                                                    </caption>
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="p-4 border border-gray-300">Nombre d'heures d'accueil par semaine</th>
                                                                            <th class="p-4 border border-gray-300">1/2 jour:<br />4h30</th>
                                                                            <th class="p-4 border border-gray-300">1 jour:<br />9h</th>
                                                                            <th class="p-4 border border-gray-300">2 jours:<br />18h</th>
                                                                            <th class="p-4 border border-gray-300">3 jours:<br />27h</th>
                                                                            <th class="p-4 border border-gray-300">4 jours:<br />36h</th>
                                                                            <th class="p-4 border border-gray-300">5 jours:<br />45h</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td class="p-4 border border-gray-300">1° niv. revenu (PAJE déduite)</td>
                                                                            <td class="p-4 border border-gray-300">18€</td>
                                                                            <td class="p-4 border border-gray-300">37€</td>
                                                                            <td class="p-4 border border-gray-300">73€</td>
                                                                            <td class="p-4 border border-gray-300">110€</td>
                                                                            <td class="p-4 border border-gray-300">147€</td>
                                                                            <td class="p-4 border border-gray-300">314€</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="p-4 border border-gray-300">2° niv. revenu (PAJE déduite)</td>
                                                                            <td class="p-4 border border-gray-300">18€</td>
                                                                            <td class="p-4 border border-gray-300">37€</td>
                                                                            <td class="p-4 border border-gray-300">73€</td>
                                                                            <td class="p-4 border border-gray-300">110€</td>
                                                                            <td class="p-4 border border-gray-300">194€</td>
                                                                            <td class="p-4 border border-gray-300">439€</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="p-4 border border-gray-300">3° niv. revenu (PAJE déduite)</td>
                                                                            <td class="p-4 border border-gray-300">18€</td>
                                                                            <td class="p-4 border border-gray-300">37€</td>
                                                                            <td class="p-4 border border-gray-300">73€</td>
                                                                            <td class="p-4 border border-gray-300">110€</td>
                                                                            <td class="p-4 border border-gray-300">320€</td>
                                                                            <td class="p-4 border border-gray-300">565€</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="p-4 border border-gray-300">coût total de l'accueil <br /> (PAJE non déduite)
                                                                            </td>
                                                                            <td class="p-4 border border-gray-300">121€</td>
                                                                            <td class="p-4 border border-gray-300">245€</td>
                                                                            <td class="p-4 border border-gray-300">490€</td>
                                                                            <td class="p-4 border border-gray-300">735€</td>
                                                                            <td class="p-4 border border-gray-300">980€</td>
                                                                            <td class="p-4 border border-gray-300">1225€</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="p-4 border border-gray-300">MENSUALISATION SUR 12 MOIS</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                        )}

                                                        {selectedTable === '6' && (
                                                            <div id="TQF6" class="overflow-x-auto mt-8">
                                                                <table class="min-w-full bg-white border border-gray-300 rounded-md">
                                                                    <caption class="p-4 text-lg font-semibold text-left text-gray-800 bg-gray-100 border-b border-gray-300 rounded-t-md">
                                                                        COÛT MENSUEL POUR LES FAMILLES POUR UN TARIF A 7.60€/h
                                                                    </caption>
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="p-4 border border-gray-300">Nombre d'heures d'accueil par semaine</th>
                                                                            <th class="p-4 border border-gray-300">1/2 jour:<br />4h30</th>
                                                                            <th class="p-4 border border-gray-300">1 jour:<br />9h</th>
                                                                            <th class="p-4 border border-gray-300">2 jours:<br />18h</th>
                                                                            <th class="p-4 border border-gray-300">3 jours:<br />27h</th>
                                                                            <th class="p-4 border border-gray-300">4 jours:<br />36h</th>
                                                                            <th class="p-4 border border-gray-300">5 jours:<br />45h</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td class="p-4 border border-gray-300">1° niv. revenu (PAJE déduite)</td>
                                                                            <td class="p-4 border border-gray-300">18€</td>
                                                                            <td class="p-4 border border-gray-300">37€</td>
                                                                            <td class="p-4 border border-gray-300">73€</td>
                                                                            <td class="p-4 border border-gray-300">110€</td>
                                                                            <td class="p-4 border border-gray-300">147€</td>
                                                                            <td class="p-4 border border-gray-300">314€</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="p-4 border border-gray-300">2° niv. revenu (PAJE déduite)</td>
                                                                            <td class="p-4 border border-gray-300">18€</td>
                                                                            <td class="p-4 border border-gray-300">37€</td>
                                                                            <td class="p-4 border border-gray-300">73€</td>
                                                                            <td class="p-4 border border-gray-300">110€</td>
                                                                            <td class="p-4 border border-gray-300">194€</td>
                                                                            <td class="p-4 border border-gray-300">439€</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="p-4 border border-gray-300">3° niv. revenu (PAJE déduite)</td>
                                                                            <td class="p-4 border border-gray-300">18€</td>
                                                                            <td class="p-4 border border-gray-300">37€</td>
                                                                            <td class="p-4 border border-gray-300">73€</td>
                                                                            <td class="p-4 border border-gray-300">110€</td>
                                                                            <td class="p-4 border border-gray-300">320€</td>
                                                                            <td class="p-4 border border-gray-300">565€</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="p-4 border border-gray-300">coût total de l'accueil <br /> (PAJE non déduite)
                                                                            </td>
                                                                            <td class="p-4 border border-gray-300">121€</td>
                                                                            <td class="p-4 border border-gray-300">245€</td>
                                                                            <td class="p-4 border border-gray-300">490€</td>
                                                                            <td class="p-4 border border-gray-300">735€</td>
                                                                            <td class="p-4 border border-gray-300">980€</td>
                                                                            <td class="p-4 border border-gray-300">1225€</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="p-4 border border-gray-300">MENSUALISATION SUR 12 MOIS</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                        )}


                                                    </div>
                                                    <div class="md:col-span-2 mt-3">
                                                        <label class="lg:text-lg" for="comment">Commentaires ou questions</label>
                                                        <textarea type="textarea" name="comment" id="comment" class="focus:outline-none whitespace-pre-wrap h-32 border mt-1 text-start rounded px-4 w-full
                                                            bg-gray-50" value={comment} onChange={(e) => setComment(e.target.value)} placeholder="Commentaires ou précisions"></textarea>
                                                    </div>

                                                    <div class="md:col-span-5 text-right mt-4">
                                                        <div class="inline-flex items-end">
                                                            <button class="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Envoyé</button>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>

                    <div><p class="text-xs text-justify italic">Les informations recueillies sur ce formulaire sont enregistrées
                        dans un fichier informatisé par la Micro-crèche Grain de Malice pour pré-inscription.
                        La base légale du traitement sont les intérêts légitimes des deux parties pour la préinscription.
                        Les données collectées seront communiquées aux seuls destinataires suivants : émetteur du formulaire et la micro-crèche Grain de Malice.
                        Les données sont conservées pendant le temps de l'inscription et pour une durée de 36 mois maximum selon le RGPD.
                        Vous pouvez accéder aux données vous concernant, les rectifier, demander leur effacement ou exercer votre droit
                        à la limitation du traitement de vos données.
                        (en fonction de la base légale du traitement, mentionner également : Vous pouvez retirer à tout moment
                        votre consentement au traitement de vos données ; Vous pouvez également vous opposer au traitement de vos
                        données ; Vous pouvez également exercer votre droit à la portabilité de vos données)
                        Consultez le site cnil.fr pour plus d’informations sur vos droits.

                        Pour exercer ces droits ou pour toute question sur le traitement de vos données dans ce dispositif,
                        vous pouvez contacter La Micro-Crèche : 41 Rue Marx Dormoy, 42300 Roanne - Tél. : 09 81 65 51 57 ou 06 69 43 55 26 - microcrecheroanne@gmail.com

                        Si vous estimez, après nous avoir contactés, que vos droits « Informatique et Libertés » ne sont pas respectés, vous pouvez adresser une réclamation à la CNIL.</p></div>


                </div>
            </section>
        </>
    )
}